.searchOverlay{ 
    position: absolute;
    background-color: #111;
    width: 100%;
    margin-top: 15px;
    max-height: calc(100vh - 75px);
    text-align: left;
    box-sizing: border-box;
    overflow-y: hidden;
    min-height: 80px;
    font-size: 1rem;
    padding-right: 10px;
}
.searchOverlay > div{ max-height: calc(100vh - 80px) !important;}
.searchOverlay a{color: #fff; text-decoration: none;}
.hymnSearchItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #222;
    padding: .5rem 0 .5rem 1rem;
    cursor: pointer;
}
.hymnSearchItem:hover{
    background-color: #171717;
}
.hymnSearchItem > h5{
    font-weight: 400;
 }
.hymnSearchItem > h5, .hymnSearchItem > h4{
   margin: 2px 0;
}


@media (max-width: 441px) {
    .searchOverlay{
        padding-right: 20px;
    }
}