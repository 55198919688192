.searchInput{
    padding: 10px 30px;
    border-radius: 20px;
    border: none;
    font-size: 1rem;
    background: #333;
    width: 100%;
    box-sizing: border-box;
    color: #ccc;
    outline: none;
    padding-left: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.searchInputWrapper{
    width: 400px;
    box-sizing: border-box;
    flex: 1 1 50%;
    position: fixed;
    transform: translateX(calc(100vw - 400px));
    background: rgb(15, 15, 15);
    padding: 0.5rem;
    transition: .3s;
}

.bringInSearch{
    flex: 1 1 50%;
    position: fixed;
    transform: translateX(100vw);
    background: rgb(15, 15, 15);
    padding: 0.5rem;
    transition: .3s;
}
.mainView{
    flex-basis: calc(100vw - 400px);
    border-right: 1px solid #333;
    transition: .3s;
}
.contractMainView{
    flex-basis: 100vw;
    border-right: 1px solid #333;
    transition: .3s;
}

@media (max-width: 767px) {
    .searchInputWrapper {
        width: 240px;
        transform: translateX(calc(100vw - 240px));
    }
    .mainView {
        flex-basis: calc(100vw - 240px);
    }
  }

  @media (max-width: 441px) {
    .mainView {
        transform: translateX(-70%);
    }

    .searchInputWrapper {
        width: calc(100% - 15%);
        transform: translateX(calc(100% - 82%));
    }
}