.navIcon{
    cursor: pointer;
    padding: 12px 13px 8px 13px;
    transition: .3s;
    color: #ccc;
}

.navIcon:hover{ 
    background: #000;
    border-radius: 50%;
    color: #fff;
}