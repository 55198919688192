.hymnHeader{
    margin: auto auto 2rem;
    background: #222; 
    height: 72px; 
    padding: 1rem; 
    font-size: 1.8rem; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-family: serif;
    transition: .3s;
}
.hymnStanza{
     margin: auto; 
     font-size: 1.5rem;
     display: flex; 
     justify-content: center; 
     font-family: serif; 
     flex-direction: column; 
     align-items: flex-start;
     max-width: 550px;
     transition: .3s;
}
.hymnStanzaNumber{
background: rgb(15, 15, 15); 
margin: .5rem 0; 
padding: .2rem 1rem; 
text-align: left; 
border-radius: 5px; 
box-sizing: border-box;
}
.hymnStanzaParagraph{
    background: rgb(15, 15, 15); 
    width: 100%; 
    padding: 1rem; 
    box-sizing: border-box; 
    border-radius: 5px; 
    text-align: left;
    white-space: break-spaces;
}


@media (max-width: 767px) {
    .hymnHeader{
        font-size: 1.2rem; 
    }
  }

  @media (max-width: 670px) {
    .hymnStanza{
        font-size: 1.2rem; 
    }
  }

  @media (max-width: 441px) {
  }

